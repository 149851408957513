import { CForm, CFormFeedback, CFormInput, CFormLabel, CFormTextarea, CSpinner } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { CFormInputWithMask } from '../../../components/CFormInputWithMask';
import axios from 'axios';
import Recaptcha from '../Components/Recaptcha';
import Swal from 'sweetalert2';
import { getAllConfigurations, getTopImage } from '../Functions/Utilities';

const Contact = () => {
    const [loading, setLoading] = useState(false);

    const [pageText, setPageText] = useState("");    
    const [configurations, setConfigurations] = useState(null);
    const [customStyles, setCustomStyles] = useState({
        backgroundColor: "#0068DB"
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/frontend/configurations/one/texto-pagina-fale-conosco`)
            .then((response) => {
                setPageText(response.data.value);
            })
            .catch((error) => {
                console.error(error);
            });
           
        const configureTopImage = () => {
            getTopImage(
                'imagem-de-topo-pagina-fale-conosco',
                'imagem-mobile-de-topo-pagina-fale-conosco',
                setConfigurations, 
                setCustomStyles
            )
        };
        
        configureTopImage();

        // Adiciona o event listener
        window.addEventListener('resize', configureTopImage);

        // Remove o event listener na limpeza do efeito
        return () => {
            window.removeEventListener('resize', configureTopImage);
        };
    },[]);

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        enterprise: '',
        subject: '',
        message: ' ',
        recaptcha: '',
    });

    const handleChange = (e) => {
        let { name, value } = e.target;
    
        setFormData({ ...formData, [name]: value });
    };

    const [invalidInputs, setInvalidInputs] = useState({
        name: false,
        email: false,
        phone: false,
        enterprise: false,
        subject: false,
        message: false,
    });

    const handleCaptchaChange = (value) => {
        setFormData({ ...formData, recaptcha: value });
    };

    const handleSubmit = async (e) => {
        if (e) {
          e.preventDefault();
        }
    
        setLoading(true);

        let validationFailedFields = {};

        try {
          await axios.post(
            `${process.env.REACT_APP_API_URL}/frontend/contact`,
            formData
          );

          setLoading(false);

          Swal.fire({
            title: "Contato efetuado com sucesso!",
            icon: 'success',
            customClass: {
                confirmButton: 'btn-fill !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] text-sm shadow-none !rounded-[50px]',
                container: "tracking-wider",
                popup: "tracking-wider",
                header: "tracking-wider",
                title: "tracking-wider",
            },
            buttonsStyling: false,
            confirmButtonText: 'OK',
        });

        } catch (error) {
            console.log(error?.response?.data);

            
            Swal.fire({
                title: error?.response?.data?.message,
                icon: 'error',
                customClass: {
                    confirmButton: 'btn-fill !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] text-sm shadow-none !rounded-[50px]',
                    container: "tracking-wider",
                    popup: "tracking-wider",
                    header: "tracking-wider",
                    title: "tracking-wider",
                },
                buttonsStyling: false,
                confirmButtonText: 'OK',
            });

            setLoading(false);
            if (error?.response?.data?.inputs) {
                validationFailedFields = {};
        
                Object.keys(invalidInputs).forEach((invalidInput) => {
                    validationFailedFields[invalidInput] = false;
                });
        
                Object.keys(error.response.data.inputs).forEach(
                    (invalidInput) => (validationFailedFields[invalidInput] = true)
                );
        
                setInvalidInputs(validationFailedFields);
            }
        }
      };

    return <>
        <section className='overflow-hidden'>
            <Row className='overflow-hidden'>
                <Col xs={12} className='h-[70vh]' style={customStyles}>
                    <Row className='text-white py-[30vh]'>
                        <Col xs={2}></Col>
                        <Col xs={10} className='!text-4xl'>Fale conosco</Col>
                    </Row>
                </Col>
                <Col xs={12} className='bg-[#FFFFFF] overflow-hidden'>
                    <CForm className="my-14" onSubmit={handleSubmit}>
                        <Row>
                            <Col xs={2}></Col>
                            <Col xs={8}>
                                <Row className='my-20'>
                                    <Col xs={12} className='text-center mb-10' dangerouslySetInnerHTML={{__html: pageText || "Entre em contato" }} ></Col>
                                    <Col xs={12} className="mt-10">
                                        <CFormLabel htmlFor="name">Nome: <span className="text-red-500">*</span></CFormLabel>
                                        <CFormInput
                                            className='text-black'
                                            invalid={invalidInputs?.name}
                                            value={formData?.name || ""}
                                            onChange={handleChange}
                                            id='name'
                                            name='name'
                                            readOnly={false}
                                        />
                                    </Col>
                                    <Col xs={6} className='mt-3'>
                                        <CFormLabel htmlFor="email">Email: <span className="text-red-500">*</span></CFormLabel>
                                        <CFormInput
                                            invalid={invalidInputs?.email}
                                            value={formData?.email || ""}
                                            onChange={handleChange}
                                            id='email'
                                            name='email'
                                            type='email'
                                            readOnly={false}
                                        />
                                    </Col>
                                    <Col xs={6} className='mt-3'>
                                        <CFormLabel htmlFor="phone">Telefone:</CFormLabel>
                                        <CFormInputWithMask
                                            mask='(00) 0 0000-0000'
                                            aria-label='phone'
                                            id='phone'
                                            name='phone'
                                            value={formData?.phone}
                                            onChange={handleChange}
                                            invalid={invalidInputs.phone}
                                        />
                                    </Col>
                                    <Col xs={12} className='mt-3'>
                                        <CFormLabel htmlFor="enterprise">Empresa:</CFormLabel>
                                        <CFormInput
                                            invalid={invalidInputs?.enterprise}
                                            value={formData?.enterprise || ""}
                                            onChange={handleChange}
                                            id='enterprise'
                                            name='enterprise'
                                            readOnly={false}
                                        />
                                    </Col>
                                    <Col xs={12} className='mt-3'>
                                        <CFormLabel htmlFor="enterprise">Assunto: <span className="text-red-500">*</span></CFormLabel>
                                        <CFormInput
                                            invalid={invalidInputs?.subject}
                                            value={formData?.subject || ""}
                                            onChange={handleChange}
                                            id='subject'
                                            name='subject'
                                            readOnly={false}
                                        />
                                    </Col>
                                    <Col xs={12} className='mt-3'>
                                        <CFormLabel htmlFor='message'>Mensagem <span className="text-red-500">*</span></CFormLabel>

                                        <CFormTextarea
                                            invalid={invalidInputs.message}
                                            value={formData.message}
                                            onChange={handleChange}
                                            id='message'
                                            name='message'
                                        />

                                        {invalidInputs.message && (
                                            <CFormFeedback invalid={invalidInputs.message}>
                                                {invalidInputs.message}
                                            </CFormFeedback>
                                        )}
                                    </Col>


                                    {/* <Col xs={1} lg={2}></Col> */}
                                    <Col xs={12}>
                                        <Row  className='!mt-8'>
                                            <Col lg={6} xs={12} className='mt-10'>
                                                <Recaptcha handleCaptchaChange={handleCaptchaChange}></Recaptcha>
                                            </Col>
                                            <Col lg={6} xs={12} className="text-right md:!mt-8 xs:!mt-0">
                                                <Button type="submit" className="btn-fill md:w-full xs:mt-8 sm:mt-8 !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] lg:!px-[32px] sm:!px-[22px] text-sm shadow-none !rounded-[50px] btn">
                                                Enviar &nbsp;
                                                    { loading 
                                                        ? (<CSpinner
                                                            component='span'
                                                            size='sm'
                                                            aria-hidden='true'
                                                        />) 
                                                        : null
                                                    }   
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {/* <Col xs={1} lg={2}></Col> */}

                                </Row>
                            </Col>
                            <Col xs={2}></Col>
                        </Row>
                    </CForm>
                </Col>
            </Row>
        </section>
    </>
};

export default Contact;