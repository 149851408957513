import About from "./frontend/src/Frontend/About";
import Blog from "./frontend/src/Frontend/Blog";
import BlogPage from "./frontend/src/Frontend/BlogPage";
import Contact from "./frontend/src/Frontend/Contact";
import EventSelectCities from "./frontend/src/Frontend/EventSelectCities";
import Home from "./frontend/src/Frontend/Home";
import LeisureTravelPackages from "./frontend/src/Frontend/LeisureTravelPackages";
import LeisureTravelSinglePackage from "./frontend/src/Frontend/LeisureTravelSinglePackage";
import NotFound from "./frontend/src/Frontend/NotFound";
import PackageSelectHotels from "./frontend/src/Frontend/PackageSelectHotels";
import PackagesDestinations from "./frontend/src/Frontend/PackagesDestinations";
import ReservationForm from "./frontend/src/Frontend/ReservationForm";
import SelectHotels from "./frontend/src/Frontend/SelectHotels";
import AirlineTickets from "./frontend/src/Frontend/Services/AirlineTickets";
import CarRent from "./frontend/src/Frontend/Services/CarRent";
import Hotels from "./frontend/src/Frontend/Services/Hotels";
import TravelInsurance from "./frontend/src/Frontend/Services/TravelInsurance";

const frontendRoutes = [
  { path: '/', name: 'Home', element: Home },
  { path: '/event/:id', name: 'EventSelectCities', element: EventSelectCities },
  { path: '/package/:id', name: 'EventSelectPackages', element: PackageSelectHotels },
  { path: '/hotels/:eventId/:cityId', name: 'SelectHotels', element: SelectHotels },
  { path: '/reservation-form', name: 'ReservationForm', element: ReservationForm },
  { path: '/contact', name: 'ContactForm', element: Contact },
  { path: '/about', name: 'About', element: About },
  { path: '/blog', name: 'Blog', element: Blog },
  { path: '/blog-page/:slug', name: 'Blog', element: BlogPage },
  { path: '/car-rent', name: 'Alugel de carros', element: CarRent },
  { path: '/airline-tickets', name: 'Passagens Aéreas', element: AirlineTickets },
  { path: '/hotels', name: 'Hospedagem', element: Hotels },
  { path: '/travel-insurance', name: 'Seguro viagens', element: TravelInsurance },
  { path: '/leisure-travel-packages', name: 'Pacotes de viagem de lazer', element: LeisureTravelPackages },
  { path: '/leisure-package/:slug', name: 'Pacote de viagem de lazer', element: LeisureTravelSinglePackage },
  { path: '/packages-destinations', name: 'Pacotes e destinos', element: PackagesDestinations },
  { path: '/not-found', name: 'Não encontrado', element: NotFound },
  { path: '/*', name: 'Não encontrado', element: NotFound },
];

export default frontendRoutes;
