import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { CButton, CForm, CFormInput, CFormLabel, CFormSelect, CFormTextarea, CInputGroup } from "@coreui/react";
import { Button, Col, Row } from "react-bootstrap";
import { BrazilianFormatData } from "../../../components/BrazilianFormatData";
import AirlineOption from "../../components/AirlineOption";
import { cilMinus, cilPlus } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { CFormInputWithMask } from "../../../components/CFormInputWithMask";
import Swal from "sweetalert2";
import Recaptcha from "../Components/Recaptcha";
import Debug from "../Components/Debug/Debug";
import { isValidCPF } from "../Functions/Utilities";

const ReservationForm = (componentParams) => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const packageId = params.get('package');
    const optionId = params.get('option');
    const hotelId = params.get('hotel');
    const eventId = params.get('event');

    const [cities, setCities] = useState([]);
    const [loadingCities, setLoadingCities] = useState(false);  

    const [eventBannerStyle, setEventBannerStyle] = useState({
        backgroundImage: 'url()',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    });

    const getCityByPostcode = (e) => {
        const postcodeValue = e.target.value.replace(/\D/g, '');
    
        if (postcodeValue.length < 8) {
          return;
        }
    
        setLoadingCities(true);
    
        axios
          .get(
            `${process.env.REACT_APP_API_URL}/admin/states/get-by-postcode/${postcodeValue}`
          )
          .then((response) => {
            setCities(response.data.cities);
    
            setLoadingCities(false);
    
            let formdataResponsible = formData.responsible;
    
            formdataResponsible.city = formdataResponsible.city || {};
            formdataResponsible.city.state = formdataResponsible.city.state || {};
    
            formdataResponsible.city.id = '';
            formdataResponsible.city.state.id = response.data.id;
    
            let currentCity = response.data.cities.filter(
              (cityToFilter) => cityToFilter.selected
            );
    
            if (currentCity.length) {
              formdataResponsible.city.id = currentCity.pop().id;
              formdataResponsible.city_id = formdataResponsible.city.id;
            }
    
            setFormData({ ...formData, responsible: formdataResponsible });
          })
          .catch((error) => {
            let formdataResponsible = formData.responsible;
    
            formdataResponsible.city = formdataResponsible.city || {};
            formdataResponsible.city.state = formdataResponsible.city.state || {};
    
            formdataResponsible.city.id = '';
            formdataResponsible.city.state.id = '';
    
            setFormData({ ...formData, responsible: formdataResponsible });
            setLoadingCities(false);
            console.error('Erro ao buscar estados:', error);
          });
    };

    const getCitiesByState = (e) => {
        const stateId = e.target.value;
    
        setLoadingCities(true);
    
        axios
          .get(`${process.env.REACT_APP_API_URL}/admin/states/cities/${stateId}`)
          .then((response) => {
            setCities(response.data);
            setLoadingCities(false);
          })
          .catch((error) => {
            setLoadingCities(false);
    
            const newFormData = { ...formData, city_id: '' };
            setFormData(newFormData);
    
            console.error('Erro ao buscar estados:', error);
          });
    };

    const [formData, setFormData] = useState({
        passengers: 1,
        passengers_list: [],
        guests: 1,
        guests_list: [],
        responsible: {
            city: {
                state: {
                    id: null
                },
                id: null
            },
            agency: "",
            phone: "",
            address: "",
            zip_code: "",
            city_id: null,
            cell_phone: "",
            name: "",
            cnpj: "",
            email: "",
        },
        packageId,
        optionId,
        hotelId,
        eventId,
        rooms: 1,
        individual_rooms: 0,
        couple_rooms: 0,
        double_rooms: 0,
        triple_rooms: 0,
        check_in_date: null,
        check_out_date: null,
        transfer: 0,
        other_information: "",
        recaptcha: "",
    });
    const [invalidInputs, setInvalidInputs] = useState({});

    const handleCaptchaChange = (value) => {
        setFormData({ ...formData, recaptcha: value });
    };

    const handleChange = (e) => {
        let { name, value } = e.target;
    
        setFormData({ ...formData, [name]: value });
    };

    const handleResponsibleChange = (e) => {
        let { name, value } = e.target;
        let responsibleData = formData.responsible;
        setFormData({ ...formData, responsible: {...responsibleData, [name]: value} });
    };

    const [packageObj, setPackage]  = useState(null);
    const [optionObj, setOption]  = useState(null);
    const [hotelObj, setHotel]  = useState(null);
    const [eventObj, setEvent]  = useState(null);
    const [airlineObj, setAirline]  = useState(null);
    const [loading, setLoading]  = useState(null);


    const validateCPFInput = (cpfValueToValidate, input) => {
        if (isValidCPF(cpfValueToValidate)) {
            input.classList.remove('is-invalid');
            return true;    
        }

        const rect = input.getBoundingClientRect();
        const offsetTop = window.scrollY + rect.top;

        window.scrollTo({
            top: offsetTop,
            behavior: 'smooth'
        });

        input.focus();

        input.classList.add('is-invalid');
        throw new Error("CPF inválido, por favor, verifique o cpf digitado.");
    };

    const handleSubmit = async (e) => {
        if (e) {
          e.preventDefault();
        }
    
        setLoading(true);
    
        try {
            formData.passengers_list.forEach((passengerFormObj, idx) => {
                const id = `#passenger_list_cpf_${idx}`;
                const input = document.querySelector(id);
                const cpfValueToValidate = passengerFormObj.cpf.replace(/\D/g, '');

                validateCPFInput(cpfValueToValidate, input);
            });

            formData.guests_list.forEach((guestFormObj, idx) => {
                const id = `#guest_list_cpf_${idx}`;
                const input = document.querySelector(id);
                const cpfValueToValidate = guestFormObj.cpf.replace(/\D/g, '');

                validateCPFInput(cpfValueToValidate, input);
            });
            
            if (!formData.recaptcha) {
                throw new Error("Por favor, preencha o reCAPTCHA.");
            }

            const req = await fetch(`${process.env.REACT_APP_API_URL}/frontend/reservation/reservation`, {
                method: 'post',
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    params: formData,
                })
            });

            setLoading(false);

            if (!req.ok) {
                const errorResponse = await req.json();
                throw new Error(errorResponse.message || 'Erro na requisição.');
            }

            Swal.fire({
                title: "Reserva efetuada com sucesso!",
                icon: 'success',
                customClass: {
                    confirmButton: 'btn-fill !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] text-sm shadow-none !rounded-[50px]',
                    container: "tracking-wider",
                    popup: "tracking-wider",
                    header: "tracking-wider",
                    title: "tracking-wider",
                },
                buttonsStyling: false,
                confirmButtonText: 'OK',
            });
        } catch (error) {
            Swal.fire({
                title: error.message,
                icon: 'error',
                customClass: {
                    confirmButton: 'btn-fill !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] text-sm shadow-none !rounded-[50px]',
                    container: "tracking-wider",
                    popup: "tracking-wider",
                    header: "tracking-wider",
                    title: "tracking-wider",
                },
                buttonsStyling: false,
                confirmButtonText: 'OK',
            });
        }
    };

    useEffect(() => {
        try {
            axios
                .get(`${process.env.REACT_APP_API_URL}/frontend/reservation/form-data?package=${packageId}&option=${optionId}&hotel=${hotelId}&event=${eventId}`)
                .then((response) => {
                    let data = response.data.data;
                    setPackage(data.package);
                    setOption(data.option);
                    setHotel(data.hotel);
                    setEvent(data.event);
                    setAirline(data.airline);

                    setEventBannerStyle({...eventBannerStyle, backgroundImage: `url(${data?.event?.photos[0]?.base64encoded})` });
                })
                .catch((error) => {
                    console.error('Erro ao buscar pacotes:', error);
                });
        } catch (error) {
            console.error('Erro ao obter configurações:', error);
        }
    }, []);

    return <>
        <div className="bg-white overflow-hidden">
            {/* Banner de topo */}
            <section className="overflow-hidden full-screen h-[50vh]" style={eventBannerStyle}></section>
            {/* Banner de topo */}

            <CForm className="my-14" onSubmit={handleSubmit}>
                {packageObj && <Row>
                    <Col xs={1} lg={2}></Col>
                    <Col xs={10} lg={8}>
                        <Row className="border !rounded-[12px] mb-10 pb-10">
                            <Col xs={12} className='bg-[#0068D9] text-white text-center uppercase tracking-wider font-bold py-10 !rounded-[12px] text-2xl'>
                                {packageObj.name}
                            </Col>
                            <Col xs={12} className='text-black text-center my-2 tracking-wide text-xl'>
                                <BrazilianFormatData date={eventObj.date_start} showTime={false} />
                                -&nbsp;
                                <BrazilianFormatData date={eventObj.date_end} showTime={false} />
                            </Col>
                            <Col xs={12} className='text-black text-center tracking-wider py-10' dangerouslySetInnerHTML={{__html: packageObj.highlights.split("\\r").join("").split("\\n").join("<br>")}}></Col>
                            {optionObj && <>
                                <Col xs={12} className=''>
                                    <AirlineOption option={optionObj}></AirlineOption>
                                </Col>
                                <Col xs={12} className=''>
                                    <CFormLabel htmlFor="passengers">Quantidade de Passageiros (informar de 1 a 6) <span className="text-red-500">*</span></CFormLabel>
                                    <CInputGroup>
                                        <CFormInput
                                            invalid={invalidInputs.passengers}
                                            value={formData?.passengers || 1}
                                            onChange={handleChange}
                                            id='passengers'
                                            name='passengers'
                                            type='number'
                                            min='1'
                                            max='6'
                                            readOnly={true}
                                        />
                                        <CButton color="danger" className="!bg-[#DC3545] text-white">
                                            <CIcon width={24} icon={cilMinus} size='xl' onClick={() => {
                                                let passengersNumber = formData.passengers - 1;
                                                
                                                if (passengersNumber < 1) {
                                                    return;
                                                }

                                                setFormData({...formData, passengers: passengersNumber });
                                            }}/>
                                        </CButton>
                                        <CButton color="success" className="!bg-[#198754] text-white">
                                            <CIcon width={24} icon={cilPlus} size='xl' onClick={() => {
                                                let passengersNumber = formData.passengers + 1;
                                                
                                                if (passengersNumber > 6) {
                                                    return;
                                                }

                                                setFormData({...formData, passengers: passengersNumber });
                                            }}/>
                                        </CButton>
                                    </CInputGroup>

                                    {Array.from({length: formData.passengers}).map((_, index) => <div key={index}>
                                        <Row>
                                            <Col xs={12}>
                                                <CFormLabel htmlFor={'passenger_list_' + index}>
                                                    Passageiro {index + 1}
                                                </CFormLabel>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={6}>
                                                <CFormLabel htmlFor={'passenger_list_' + index}>
                                                    Nome <span className="text-red-500">*</span>
                                                </CFormLabel>
                                                <CFormInput
                                                    required={true}
                                                    key={index}
                                                    value={formData?.passengers_list[index] ? formData.passengers_list[index].name : ""}
                                                    onChange={(e) => {
                                                        let { name, value } = e.target;
                                                        name = name.replace('passenger_list_', '');

                                                        let currentSetPassengers = formData.passengers_list;
                                                        currentSetPassengers[name] = currentSetPassengers[name] || { name: '', cpf: '', birthday: '' };

                                                        currentSetPassengers[name].name = value;

                                                        setFormData({...formData, passengers_list: currentSetPassengers});
                                                    }}
                                                    id={'passenger_list_' + index}
                                                    name={'passenger_list_' + index}
                                                ></CFormInput>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <CFormLabel htmlFor={'passenger_list_cpf_' + index}>
                                                    CPF <span className="text-red-500">*</span>
                                                </CFormLabel>

                                                <CFormInputWithMask
                                                    className="cpf"
                                                    required={true}
                                                    mask='000.000.000-00'
                                                    key={index}
                                                    value={formData?.passengers_list[index] ? formData.passengers_list[index].cpf : ""}
                                                    onChange={(e) => {
                                                        let { name, value } = e.target;
                                                        name = name.replace('passenger_list_cpf_', '');

                                                        let currentSetPassengers = formData.passengers_list;
                                                        currentSetPassengers[name] = currentSetPassengers[name] || { name: '', cpf: '', birthday: '' };

                                                        currentSetPassengers[name].cpf = value;

                                                        setFormData({...formData, passengers_list: currentSetPassengers});
                                                    }}
                                                    id={'passenger_list_cpf_' + index}
                                                    name={'passenger_list_cpf_' + index}
                                                ></CFormInputWithMask>
                                            </Col>
                                            <Col xs={12} md={3}>
                                                <CFormLabel htmlFor={'passenger_list_birthday_' + index}>
                                                    Data de nascimento <span className="text-red-500">*</span>
                                                </CFormLabel>
                                                <CFormInput
                                                    required={true}
                                                    key={index}
                                                    value={formData?.passengers_list[index] ? formData.passengers_list[index].birthday : ""}
                                                    type="date"
                                                    onChange={(e) => {
                                                        let { name, value } = e.target;
                                                        name = name.replace('passenger_list_birthday_', '');

                                                        let currentSetPassengers = formData.passengers_list;
                                                        currentSetPassengers[name] = currentSetPassengers[name] || { name: '', cpf: '', birthday: '' };

                                                        currentSetPassengers[name].birthday = value;

                                                        setFormData({...formData, passengers_list: currentSetPassengers});
                                                    }}
                                                    id={'passenger_list_birthday_' + index}
                                                    name={'passenger_list_birthday_' + index}
                                                ></CFormInput>
                                            </Col>
                                        </Row>
                                    </div>)}

                                </Col>
                            </>}
                        </Row>
                    </Col>
                </Row>}

                {/* Hospedagem */}
                {hotelObj && <Row className="">
                    <Col xs={1} lg={2}></Col>
                    <Col xs={10} lg={8}>
                        <Row className="border !rounded-[12px] mb-10">
                            <Col xs={12} className='bg-[#0068D9] text-white text-center uppercase tracking-wider font-bold py-10 !rounded-[12px] text-2xl'>
                                Hospedagem
                            </Col>
                            <Col xs={12} className='text-center text-xl my-10'>
                                <b>Hotel selecionado:</b>&nbsp;
                                {hotelObj.name} 
                                {Array.from({length: hotelObj.stars}).map((_, index) => <span key={index}>
                                    &#11088;
                                </span>)}
                            </Col>
                            <Col xs={12} className='text-center'>
                                {hotelObj.address}
                            </Col>

                            <Col xs={12} className=''>
                                <CFormLabel htmlFor="rooms">Quantidade de quartos (informar de 1 a 6) <span className="text-red-500">*</span></CFormLabel>
                                <CInputGroup>
                                    <CFormInput
                                        invalid={invalidInputs.rooms}
                                        value={formData?.rooms || 1}
                                        onChange={handleChange}
                                        id='rooms'
                                        name='rooms'
                                        type='number'
                                        min='1'
                                        max='6'
                                        readOnly={true}
                                    />
                                    <CButton color="danger" className="!bg-[#DC3545] text-white">
                                        <CIcon width={24} icon={cilMinus} size='xl' onClick={() => {
                                            let roomsNumber = formData.rooms - 1;
                                            
                                            if (roomsNumber < 1) {
                                                return;
                                            }

                                            setFormData({...formData, rooms: roomsNumber });
                                        }}/>
                                    </CButton>
                                    <CButton color="success" className="!bg-[#198754] text-white">
                                        <CIcon width={24} icon={cilPlus} size='xl' onClick={() => {
                                            let roomsNumber = formData.rooms + 1;
                                            
                                            if (roomsNumber > 6) {
                                                return;
                                            }

                                            setFormData({...formData, rooms: roomsNumber });
                                        }}/>
                                    </CButton>
                                </CInputGroup>
                            </Col>

                            <Col xs={6} sm={6} md={6} lg={3} className=''>
                                <CFormLabel htmlFor="individual_rooms">Individual <span className="text-red-500">*</span></CFormLabel>
                                <CInputGroup>
                                    <CFormInput
                                        invalid={invalidInputs.individual_rooms}
                                        value={formData?.individual_rooms || ""}
                                        onChange={handleChange}
                                        id='individual_rooms'
                                        name='individual_rooms'
                                        type='number'
                                        min='1'
                                        max='6'
                                        readOnly={true}
                                    />
                                    <CButton color="danger" className="!bg-[#DC3545] text-white">
                                        <CIcon width={24} icon={cilMinus} size='xl' onClick={() => {
                                            let individualRoomsNumber = formData.individual_rooms - 1;
                                            
                                            if (individualRoomsNumber < 0) {
                                                return;
                                            }

                                            setFormData({...formData, individual_rooms: individualRoomsNumber });
                                        }}/>
                                    </CButton>
                                    <CButton color="success" className="!bg-[#198754] text-white">
                                        <CIcon width={24} icon={cilPlus} size='xl' onClick={() => {
                                            let individualRoomsNumber = formData.individual_rooms + 1;
                                            
                                            if (individualRoomsNumber > 6) {
                                                return;
                                            }

                                            setFormData({...formData, individual_rooms: individualRoomsNumber });
                                        }}/>
                                    </CButton>
                                </CInputGroup>
                            </Col>

                            <Col xs={6} sm={6} md={6} lg={3} className=''>
                                <CFormLabel htmlFor="couple_rooms">Casal <span className="text-red-500">*</span></CFormLabel>
                                <CInputGroup>
                                    <CFormInput
                                        invalid={invalidInputs.couple_rooms}
                                        value={formData?.couple_rooms || ""}
                                        onChange={handleChange}
                                        id='couple_rooms'
                                        name='couple_rooms'
                                        type='number'
                                        min='1'
                                        max='6'
                                        readOnly={true}
                                    />
                                    <CButton color="danger" className="!bg-[#DC3545] text-white">
                                        <CIcon width={24} icon={cilMinus} size='xl' onClick={() => {
                                            let coupleRoomsNumber = formData.couple_rooms - 1;
                                            
                                            if (coupleRoomsNumber < 0) {
                                                return;
                                            }

                                            setFormData({...formData, couple_rooms: coupleRoomsNumber });
                                        }}/>
                                    </CButton>
                                    <CButton color="success" className="!bg-[#198754] text-white">
                                        <CIcon width={24} icon={cilPlus} size='xl' onClick={() => {
                                            let coupleRoomsNumber = formData.couple_rooms + 1;
                                            
                                            if (coupleRoomsNumber > 6) {
                                                return;
                                            }

                                            setFormData({...formData, couple_rooms: coupleRoomsNumber });
                                        }}/>
                                    </CButton>
                                </CInputGroup>
                            </Col>

                            <Col xs={6} sm={6} md={6} lg={3} className=''>
                                <CFormLabel htmlFor="double_rooms">Duplo <span className="text-red-500">*</span></CFormLabel>
                                <CInputGroup>
                                    <CFormInput
                                        invalid={invalidInputs.double_rooms}
                                        value={formData?.double_rooms || ""}
                                        onChange={handleChange}
                                        id='double_rooms'
                                        name='double_rooms'
                                        type='number'
                                        min='1'
                                        max='6'
                                        readOnly={true}
                                    />
                                    <CButton color="danger" className="!bg-[#DC3545] text-white">
                                        <CIcon width={24} icon={cilMinus} size='xl' onClick={() => {
                                            let doubleRoomsNumber = formData.double_rooms - 1;
                                            
                                            if (doubleRoomsNumber < 0) {
                                                return;
                                            }

                                            setFormData({...formData, double_rooms: doubleRoomsNumber });
                                        }}/>
                                    </CButton>
                                    <CButton color="success" className="!bg-[#198754] text-white">
                                        <CIcon width={24} icon={cilPlus} size='xl' onClick={() => {
                                            let doubleRoomsNumber = formData.double_rooms + 1;
                                            
                                            if (doubleRoomsNumber > 6) {
                                                return;
                                            }

                                            setFormData({...formData, double_rooms: doubleRoomsNumber });
                                        }}/>
                                    </CButton>
                                </CInputGroup>
                            </Col>

                            <Col xs={6} sm={6} md={6} lg={3} className=''>
                                <CFormLabel htmlFor="triple_rooms">Triplo <span className="text-red-500">*</span></CFormLabel>
                                <CInputGroup>
                                    <CFormInput
                                        invalid={invalidInputs.triple_rooms}
                                        value={formData?.triple_rooms || ""}
                                        onChange={handleChange}
                                        id='triple_rooms'
                                        name='triple_rooms'
                                        type='number'
                                        min='1'
                                        max='6'
                                        readOnly={true}
                                    />
                                    <CButton color="danger" className="!bg-[#DC3545] text-white">
                                        <CIcon width={24} icon={cilMinus} size='xl' onClick={() => {
                                            let tripleRoomsNumber = formData.triple_rooms - 1;
                                            
                                            if (tripleRoomsNumber < 0) {
                                                return;
                                            }

                                            setFormData({...formData, triple_rooms: tripleRoomsNumber });
                                        }}/>
                                    </CButton>
                                    <CButton color="success" className="!bg-[#198754] text-white">
                                        <CIcon width={24} icon={cilPlus} size='xl' onClick={() => {
                                            let tripleRoomsNumber = formData.triple_rooms + 1;
                                            
                                            if (tripleRoomsNumber > 6) {
                                                return;
                                            }

                                            setFormData({...formData, triple_rooms: tripleRoomsNumber });
                                        }}/>
                                    </CButton>
                                </CInputGroup>
                            </Col>

                            <Col xs={6}>
                                <CFormLabel htmlFor="check_in_date">Data de entrada (check-in): <span className="text-red-500">*</span></CFormLabel>
                                <CFormInput
                                    style={{ WebkitAppearance: 'menulist-button' }}
                                    invalid={invalidInputs.check_in_date}
                                    value={formData?.check_in_date || ""}
                                    onChange={handleChange}
                                    id='check_in_date'
                                    name='check_in_date'
                                    type='date'
                                    readOnly={false}
                                    required={true}
                                />
                            </Col>
                            <Col xs={6}>
                                <CFormLabel htmlFor="check_out_date">Data de saída (check-out): <span className="text-red-500">*</span></CFormLabel>
                                <CFormInput
                                    invalid={invalidInputs.check_out_date}
                                    value={formData?.check_out_date || ""}
                                    onChange={handleChange}
                                    id='check_out_date'
                                    name='check_out_date'
                                    type='date'
                                    readOnly={false}
                                    required={true}
                                />
                            </Col>

                            <Col xs={12}>
                                <CFormLabel htmlFor="transfer">Deseja contratar transfer? (aeroporto x hotel x aeroporto)</CFormLabel>
                                <CFormSelect
                                    value={formData.transfer || ""}
                                    onChange={handleChange}
                                    id='transfer'
                                    name='transfer'
                                >
                                    <option value="0">
                                        Não
                                    </option>
                                    <option value="1">
                                        Sim
                                    </option>
                                </CFormSelect>
                            </Col>

                            <Col xs={12} className=''>
                                <CFormLabel htmlFor="guests">Quantidade de hóspedes (informar de 1 a 6) <span className="text-red-500">*</span></CFormLabel>
                                <CInputGroup>
                                    <CFormInput
                                        invalid={invalidInputs.guests}
                                        value={formData?.guests || ""}
                                        onChange={handleChange}
                                        id='guests'
                                        name='guests'
                                        type='number'
                                        min='1'
                                        max='6'
                                        readOnly={true}
                                    />
                                    <CButton color="danger" className="!bg-[#DC3545] text-white">
                                        <CIcon width={24} icon={cilMinus} size='xl' onClick={() => {
                                            let guestsNumber = formData.guests - 1;
                                            
                                            if (guestsNumber < 1) {
                                                return;
                                            }

                                            setFormData({...formData, guests: guestsNumber });
                                        }}/>
                                    </CButton>
                                    <CButton color="success" className="!bg-[#198754] text-white">
                                        <CIcon width={24} icon={cilPlus} size='xl' onClick={() => {
                                            let guestsNumber = formData.guests + 1;
                                            
                                            if (guestsNumber > 6) {
                                                return;
                                            }

                                            setFormData({...formData, guests: guestsNumber });
                                        }}/>
                                    </CButton>
                                </CInputGroup>

                                {Array.from({length: formData.guests}).map((_, index) => <div key={index}>
                                    <Row>
                                        <Col xs={12}>
                                            <CFormLabel htmlFor={'guest_list_' + index}>
                                                Hóspede {index + 1}
                                            </CFormLabel>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={3}>
                                            <CFormLabel htmlFor={'guest_list_' + index}>
                                                Nome <span className="text-red-500">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                required={true}
                                                key={index}
                                                value={formData?.guests_list[index] ? formData.guests_list[index].name : ""}
                                                onChange={(e) => {
                                                    let { name, value } = e.target;
                                                    name = name.replace('guest_list_', '');

                                                    let currentSetPassengers = formData.guests_list;
                                                    currentSetPassengers[name] = currentSetPassengers[name] || { name: '', cpf: '', birthday: '' };

                                                    currentSetPassengers[name].name = value;

                                                    setFormData({...formData, guests_list: currentSetPassengers});
                                                }}
                                                id={'guest_list_' + index}
                                                name={'guest_list_' + index}
                                            ></CFormInput>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <CFormLabel htmlFor={'guest_list_cpf_' + index}>
                                                CPF <span className="text-red-500">*</span>
                                            </CFormLabel>

                                            <CFormInputWithMask
                                                className="cpf"
                                                required={true}
                                                mask='000.000.000-00'
                                                key={index}
                                                value={formData?.guests_list[index] ? formData.guests_list[index].cpf : ""}
                                                onChange={(e) => {
                                                    let { name, value } = e.target;
                                                    name = name.replace('guest_list_cpf_', '');

                                                    let currentSetPassengers = formData.guests_list;
                                                    currentSetPassengers[name] = currentSetPassengers[name] || { name: '', cpf: '', birthday: '' };

                                                    currentSetPassengers[name].cpf = value;

                                                    setFormData({...formData, guests_list: currentSetPassengers});
                                                }}
                                                id={'guest_list_cpf_' + index}
                                                name={'guest_list_cpf_' + index}
                                            ></CFormInputWithMask>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <CFormLabel htmlFor={'guest_list_birthday_' + index}>
                                                Data de nascimento <span className="text-red-500">*</span>
                                            </CFormLabel>
                                            <CFormInput
                                                required={true}
                                                key={index}
                                                value={formData?.guests_list[index] ? formData.guests_list[index].birthday : ""}
                                                type="date"
                                                onChange={(e) => {
                                                    let { name, value } = e.target;
                                                    name = name.replace('guest_list_birthday_', '');

                                                    let currentSetPassengers = formData.guests_list;
                                                    currentSetPassengers[name] = currentSetPassengers[name] || { name: '', cpf: '', birthday: '' };

                                                    currentSetPassengers[name].birthday = value;

                                                    setFormData({...formData, guests_list: currentSetPassengers});
                                                }}
                                                id={'guest_list_birthday_' + index}
                                                name={'guest_list_birthday_' + index}
                                            ></CFormInput>
                                        </Col>
                                        <Col xs={12} md={3}>
                                            <CButton
                                                className="btn-fill md:w-full mt-8 xs:mt-8 sm:mt-8 !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[22px] !py-[10px] lg:!px-[32px] sm:!px-[22px] text-sm shadow-none !rounded-[50px] btn"
                                                onClick={() => {
                                                    let currentSetPassengers = formData.guests_list;
                                                    currentSetPassengers[index] = {...formData.passengers_list[index]};
                                                    setFormData({...formData, guests_list: currentSetPassengers});
                                                }}
                                                disabled={formData?.passengers_list[index] ? false : true}
                                            >
                                                Copiar passageiro {index + 1}
                                            </CButton>
                                        </Col>
                                    </Row>
                                </div>)}
                                
                            </Col>

                            <Col xs={12} className='my-10'>
                                <CFormLabel htmlFor="other_information">Outras informações</CFormLabel>
                                <CFormTextarea
                                    value={formData?.other_information || ""}
                                    onChange={handleChange}
                                    id='other_information'
                                    name='other_information'
                                ></CFormTextarea>
                            </Col>

                            {/* Condições do pacote */}
                            {packageObj && <section>
                                <Row className='my-10'>
                                    <Col xs={1} lg={2}></Col>
                                    <Col xs={10} lg={8} className='border rounded-[12px] py-4'>
                                        <div className='text-black font-bold tracking-wider text-lg my-4'>
                                            Condições gerais:
                                        </div>
                                        <div className='text-black tracking-wider text-lg'
                                            dangerouslySetInnerHTML={{
                                                __html: packageObj?.condition?.description,
                                            }}
                                        >
                                        </div>
                                    </Col>
                                    <Col xs={1} lg={2}></Col>
                                </Row>
                            </section>}
                            {/* Condições do pacote */}
                        </Row>
                    </Col>
                    <Col xs={1} lg={2}></Col>
                </Row>}
                {/* Hospedagem */}
            
                {/* Dados para fatura */}
                <Row className="">
                    <Col xs={1} lg={2}></Col>
                    <Col xs={10} lg={8}>
                        <Row className="border !rounded-[12px] mb-10 pb-10">
                            <Col xs={12} className='bg-[#0068D9] text-white text-center uppercase tracking-wider font-bold py-10 !rounded-[12px] text-2xl'>
                                Dados para fatura
                            </Col>
                            <Col xs={12} className="mt-10">
                                <CFormLabel htmlFor="agency">Nome do órgão/entidade (Prefeitura/Câmara/Associação): <span className="text-red-500">*</span></CFormLabel>
                                <CFormInput
                                    style={{ WebkitAppearance: 'menulist-button' }}
                                    invalid={invalidInputs?.responsible?.agency}
                                    value={formData?.responsible?.agency || ""}
                                    onChange={handleResponsibleChange}
                                    id='agency'
                                    name='agency'
                                    readOnly={false}
                                />
                            </Col>
                            <Col xs={12} className="">
                                <CFormLabel htmlFor='name'>
                                    Nome
                                </CFormLabel>
                                <CFormInputWithMask
                                    aria-label='Nome'
                                    onChange={handleResponsibleChange}
                                    value={
                                    formData.responsible?.name
                                        ? formData.responsible?.name
                                        : ''
                                    }
                                    id='name'
                                    name='name'
                                    readOnly={false}
                                    required={true}
                                />
                            </Col>
                            <Col xs={12} className="">
                                <CFormLabel htmlFor='cnpj'>
                                    CNPJ
                                </CFormLabel>
                                <CFormInputWithMask
                                    aria-label='CNPJ'
                                    mask='00.000.000/0000-00'
                                    onChange={(e) => {
                                        formData.responsible.cnpj = e.target.value
                                        setFormData({...formData})
                                    }}
                                    value={
                                    formData.responsible?.cnpj
                                        ? formData.responsible?.cnpj
                                        : ''
                                    }
                                    id='cnpj'
                                    name='cnpj'
                                    readOnly={false}
                                />
                            </Col>
                            <Col xs={12} className="">
                                <CFormLabel htmlFor='zip_code'>
                                    CEP
                                </CFormLabel>
                                <CFormInputWithMask
                                    aria-label='CEP'
                                    mask='00000-000'
                                    onChange={(e) => {
                                        getCityByPostcode(e);
                                        formData.responsible.zip_code = e.target.value
                                        setFormData({...formData})
                                    }}
                                    value={
                                    formData.responsible?.zip_code
                                        ? formData.responsible?.zip_code
                                        : ''
                                    }
                                    id='zip_code'
                                    name='zip_code'
                                    readOnly={false}
                                />
                            </Col>
                            <Col xs={12} className="">
                                <CFormLabel htmlFor='zip_code'>
                                    Estado/Cidade
                                </CFormLabel>
                                <CInputGroup>
                                    <CFormSelect
                                        value={formData.responsible?.city?.state?.id || ""}
                                        onChange={(e) => {
                                            getCitiesByState(e);
                                            formData.responsible.city.state.id = e.target.value
                                            setFormData({...formData})
                                        }}
                                    >
                                        <option value="">Selecione um estado</option>
                                        <option value="1">Acre</option>
                                        <option value="2">Alagoas</option>
                                        <option value="3">Amapá</option>
                                        <option value="4">Amazonas</option>
                                        <option value="5">Bahia</option>
                                        <option value="6">Ceará</option>
                                        <option value="7">Distrito Federal</option>
                                        <option value="8">Espírito Santo</option>
                                        <option value="9">Goiás</option>
                                        <option value="10">Maranhão</option>
                                        <option value="11">Mato Grosso</option>
                                        <option value="12">Mato Grosso do Sul</option>
                                        <option value="13">Minas Gerais</option>
                                        <option value="14">Pará</option>
                                        <option value="15">Paraíba</option>
                                        <option value="16">Paraná</option>
                                        <option value="17">Pernambuco</option>
                                        <option value="18">Piauí</option>
                                        <option value="19">Rio de Janeiro</option>
                                        <option value="20">Rio Grande do Norte</option>
                                        <option value="21">Rio Grande do Sul</option>
                                        <option value="22">Rondônia</option>
                                        <option value="23">Roraima</option>
                                        <option value="24">Santa Catarina</option>
                                        <option value="25">São Paulo</option>
                                        <option value="26">Sergipe</option>
                                        <option value="27">Tocantins</option>
                                    </CFormSelect>

                                    <CFormSelect
                                        value={formData?.responsible?.city?.id || ""}
                                        onChange={(e) => {
                                            formData.responsible.city.id = e.target.value
                                            setFormData({...formData});
                                        }}
                                    >
                                        {
                                            cities.length
                                                ? cities.map((city, indexCity) => (
                                                    <option value={city.id} key={indexCity}>{city.name}</option>
                                                ))
                                                : <option>Selecione um estado para poder escolher uma cidade</option>
                                        }
                                    </CFormSelect>
                                </CInputGroup>
                            </Col>
                            
                            <Col xs={6}>
                                <CFormLabel htmlFor='address'>
                                    Endereço
                                </CFormLabel>
                                <CFormInputWithMask
                                    onChange={handleResponsibleChange}
                                    value={
                                    formData.responsible?.address
                                        ? formData.responsible?.address
                                        : ''
                                    }
                                    id='address'
                                    name='address'
                                    readOnly={false}
                                />
                            </Col>

                            <Col xs={6}>
                                <CFormLabel htmlFor='phone'>
                                    Telefone fixo
                                </CFormLabel>

                                <CFormInputWithMask
                                    mask='(00) 0000-0000'
                                    aria-label='Telefone fixo'
                                    id='phone'
                                    name='phone'
                                    value={formData?.responsible?.phone || ""}
                                    onChange={handleResponsibleChange}
                                    invalid={invalidInputs.phone}
                                />
                            </Col>

                            <Col xs={6}>
                                <CFormLabel htmlFor='cell_phone'>
                                    Celular
                                </CFormLabel>

                                <CFormInputWithMask
                                    mask='(00) 0 0000-0000'
                                    aria-label='Celular'
                                    id='cell_phone'
                                    name='cell_phone'
                                    value={formData?.responsible?.cell_phone || ""}
                                    onChange={handleResponsibleChange}
                                    invalid={invalidInputs.cell_phone}
                                />
                            </Col>

                            <Col xs={6}>
                                <CFormLabel htmlFor='fax'>
                                    Fax
                                </CFormLabel>

                                <CFormInputWithMask
                                    mask='(00) 0000-0000'
                                    aria-label='fax'
                                    id='fax'
                                    name='fax'
                                    value={formData?.responsible?.fax || ""}
                                    onChange={handleResponsibleChange}
                                    invalid={invalidInputs.fax}
                                />
                            </Col>

                            <Col xs={12}>
                                <CFormLabel htmlFor='email'>
                                    Email
                                </CFormLabel>

                                <CFormInputWithMask
                                    aria-label='email'
                                    id='email'
                                    name='email'
                                    type='email'
                                    value={formData?.responsible?.email || ""}
                                    onChange={handleResponsibleChange}
                                    invalid={invalidInputs.email}
                                />
                            </Col>

                            <Col xs={12}>
                                <CFormLabel htmlFor='position'>
                                    Cargo
                                </CFormLabel>

                                <CFormInputWithMask
                                    aria-label='position'
                                    id='position'
                                    name='position'
                                    value={formData?.responsible?.position || ""}
                                    onChange={handleResponsibleChange}
                                    invalid={invalidInputs.position}
                                />
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={1} lg={2}></Col>


                    <Col xs={1} lg={2}></Col>
                    <Col xs={10} lg={8}>
                        <Row>
                            <Col lg={6} xs={12} className='mt-10'>
                                <Recaptcha handleCaptchaChange={handleCaptchaChange}></Recaptcha>
                            </Col>
                            <Col lg={6} xs={12} className="text-right md:!mt-8 xs:!mt-0">
                                <Button type="submit" className="btn-fill md:w-full xs:mt-8 sm:mt-8 !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] lg:!px-[32px] sm:!px-[22px] text-sm shadow-none !rounded-[50px] btn">
                                    Realizar reserva
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                    <Col xs={1} lg={2}></Col>
                </Row>
                {/* Dados para fatura */}
                
            </CForm>
        </div>
    </>
};

export default ReservationForm;