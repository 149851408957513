import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";

const BlogPage = () => {
    const [page, setPage] = useState(null);
    const { slug } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/frontend/blog/${slug}`)
            .then(response => {
                setPage(response?.data?.data);
            })
            .catch(error => {
                if (error.response && error.response.status === 404) {
                    return navigate('/frontend/not-found');
                }
                console.error("An unexpected error occurred:", error);
            });
    }, [navigate]);

    return <section className='overflow-hidden'>
        <Row className='overflow-hidden'>
            <Col xs={12} className='h-[50vh]' style={{backgroundImage: `url(${page?.bannerData?.base64encoded})`}}>
                <Row className='text-white py-[22vh] !leading-10'>
                    <Col xs={2}></Col>
                    <Col lg={3}>
                        <div className='!text-4xl lg:px-16'>{page?.title}</div>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} className='bg-[#F9F9FB]'>
                <Row className='mb-10'>
                    <Col xs={1} lg={2}></Col>
                    <Col xs={10} lg={8} className='leading-8 text-black lg:px-16 mt-10' dangerouslySetInnerHTML={{__html: page?.text}}></Col>
                    <Col xs={1} lg={2}></Col>
                </Row>
            </Col>
        </Row>
    </section>
};

export default BlogPage;