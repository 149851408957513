const HeaderData = [
    {
        title: 'Serviços',
        dropdown: [
            {

                title: "Aluguel de carros",
                link: "/frontend/car-rent"
            },
            {

                title: "Passagens Aéreas",
                link: "/frontend/airline-tickets"
            },
            {

                title: "Hospedagem",
                link: "/frontend/hotels"
            },
            {

                title: "Seguro viagens",
                link: "/frontend/travel-insurance"
            },
        ]
    },
    {
        title: 'Quem somos',
        link: '/frontend/about'
    },
    {
        title: 'Pacotes e destinos',
        link: '/frontend/packages-destinations'
    },
    {
        title: 'Fale conosco',
        link: '/frontend/contact'
    },
    {
        title: 'Blog',
        link: '/frontend/blog'
    },
]

export default HeaderData