import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Col, Row } from 'react-bootstrap';
import CIcon from '@coreui/icons-react';
import { cilAirplaneMode } from '@coreui/icons';

const EventSelectCities = (props) => {
    const [packages, setPackages] = useState([]);
    const [packagesSecondHalf, setPackagesSecondHalf] = useState([]);
    const [eventData, setEventData] = useState([]);
    const [eventBannerStyle, setEventBannerStyle] = useState({
        backgroundImage: 'url()',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
    });

    const { id } = useParams();

    useEffect(() => {
        try {
            axios.get(`${process.env.REACT_APP_API_URL}/frontend/events/${id}`)
                .then(response => {
                    setEventData(response.data);
                    setEventBannerStyle({...eventBannerStyle, backgroundImage: `url(${response.data?.data?.photos[0]?.base64encoded})` });
                });
            
            axios
                .get(`${process.env.REACT_APP_API_URL}/frontend/packages?event_id=${id}`)
                .then((response) => {
                    let data = response.data.data;
                    data.sort((a, b) => {
                        const cityA = a.origin_city.name.toUpperCase();
                        const cityB = b.origin_city.name.toUpperCase();
            
                        if (cityA < cityB) {
                            return -1;
                        }
                        if (cityA > cityB) {
                            return 1;
                        }
            
                        return 0;
                    });
                    
                    const halfLength = Math.ceil(data.length / 2);

                    const firstHalf = data.slice(0, halfLength);
                    const secondHalf = data.slice(halfLength);

                    setPackages(firstHalf);
                    setPackagesSecondHalf(secondHalf);
                })
                .catch((error) => {
                    console.error('Erro ao buscar pacotes:', error);
                });
        } catch (error) {
            console.error('Erro ao obter configurações:', error);
        }
    }, []);

    return <>
        <div className="bg-white overflow-hidden">
            {/* Banner do topo da página */}
            <section className="overflow-hidden full-screen h-[50vh]" style={eventBannerStyle}></section>
            {/* Fim do banner de topo */}

            <section>
                <Row>
                    <Col xs={12} className='text-[#0068D9] text-center my-10 uppercase tracking-wider'>
                        Evento
                    </Col>
                    <Col xs={12} className='text-black text-center my-2 uppercase tracking-wider text-4xl'>
                        {eventData?.data?.name}
                    </Col>
                    <Col lg={4} md={1}></Col>
                    <Col lg={4} md={10} className='text-black text-center my-2 tracking-wide text-xl'>
                        Selecione a cidade de origem abaixo para realizar a reserva do seu pacote para o evento&nbsp;{eventData?.data?.name}
                    </Col>
                    <Col lg={4} md={1}></Col>
                </Row>
            </section>
            <section className='my-10 border mx-[20%] sm:mx-[5%]'>
                <Row>
                    <Col lg={4} md={12} className='text-center'>
                        {packages.map((package_, index) => (
                            <p key={index} className="my-10">
                               <Button
                                    aria-label="link"
                                    className="page-link py-2 font-light uppercase text-2xl"
                                    href={`#/frontend/package/${package_.id}`}
                                >
                                    <span className="hover:bg-[#DC3545] !rounded-[50px] hover:text-white px-4 py-2">
                                        {package_.origin_city.name}/{package_.origin_city.state.acronym}
                                    </span>
                                </Button>
                            </p>
                        ))}
                    </Col>

                    <Col xs={4} className='text-center xs:hidden md:hidden'>
                        {/* Ícone centralizado verticalmente */}
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }} className='rotate-45'>
                            <span className='!rounded-[500px] bg-black text-white p-4'>
                                <CIcon icon={cilAirplaneMode} size='sm' className='rotate-180 h-20' />
                            </span>
                        </div>
                    </Col>

                    <Col lg={4} xs={12} className='text-center'>
                        {packagesSecondHalf.map((package_, index) => (
                            <p key={index} className="my-10">
                                <Button
                                    aria-label="link"
                                    className="page-link py-2 font-light uppercase text-2xl"
                                    href={`#/frontend/package/${package_.id}`}
                                >
                                    <span className="hover:bg-[#DC3545] !rounded-[50px] hover:text-white px-4 py-2">
                                        {package_.origin_city.name}/{package_.origin_city.state.acronym}
                                    </span>
                                </Button>
                            </p>
                        ))}
                    </Col>
                </Row>
            </section>
            <section>
                <Row className='mb-10'>
                    <Col xs={12} className='text-black text-center my-2 uppercase tracking-wider text-4xl'>
                        Hospedagem
                    </Col>

                    <Col md={4} lg={1}></Col>
                    <Col md={4} lg={10} className='text-black text-center my-2 tracking-wide text-xl'>
                        Se optar somente pela contratação de hospedagem, clique no botão abaixo:
                    </Col>
                    <Col md={4} lg={1}></Col>

                    <Col md={4} lg={1}></Col>
                    <Col md={4} lg={10} className='text-black text-center my-2 tracking-wide text-xl'>
                        <Button
                            aria-label="link"
                            className="page-link font-light text-2xl hover:bg-[#DC3545] !rounded-[50px] hover:text-white px-4 py-4 border !border-red-500"
                            href={`#/frontend/hotels/${id}/${eventData?.data?.city?.id}`}
                        >
                            Clique aqui para somente hospedagem!
                        </Button>
                    </Col>
                    <Col md={4} lg={1}></Col>
                </Row>
            </section>

        </div>
    </>

};

export default EventSelectCities;