import React from 'react';
import { Col, Row } from 'react-bootstrap';

const NotFound = () => {
    return <section className='overflow-hidden'>
        <Row className='h-[8vh] overflow-x-hidden bg-[#23262d]'></Row>
        <Row className='h-[12vh] overflow-x-hidden mt-[100px]'>
            <Col className='text-5xl text-black text-center'>
                Página não encontrada.
            </Col>
        </Row>
    </section>
};

export default NotFound;