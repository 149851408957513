const FooterData = [
    {
        title: "Serviços",
        submenu: [
            {

                title: "Aluguel de carros",
                link: "/frontend/car-rent"
            },
            {

                title: "Passagens Aéreas",
                link: "/frontend/airline-tickets"
            },
            {

                title: "Hospedagem",
                link: "/frontend/hotels"
            },
            {

                title: "Seguro viagens",
                link: "/frontend/travel-insurance"
            },
        ]
    },
    {
        title: "Intranet",
        submenu: [
            {

                title: "Login",
                link: "/login"
            },
        ],
    },
]

export default FooterData