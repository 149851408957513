import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { fadeIn } from "../Functions/GlobalAnimations";
import InfoBannerStyle01 from "../Components/InfoBanner/InfoBannerStyle01";
import { getAllConfigurations, getTopImage } from "../Functions/Utilities";
import Debug from "../Components/Debug/Debug";
import { CAlert } from "@coreui/react";

const LeisureTravelPackages = () => {
    const [pages, setPages] = useState(null);
    const [configurations, setConfigurations] = useState(null);
    const [customStyles, setCustomStyles] = useState({
        backgroundColor: "#0068DB",
    });

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/frontend/leisure_packages`)
            .then(response => {
                setPages(response?.data?.data);
            });

        const configureTopImage = () => {
            getTopImage(
                'imagem-de-topo-pagina-pacotes-de-viagens-de-lazer',
                'imagem-mobile-de-topo-pagina-pacotes-de-viagens-de-lazer',
                setConfigurations,
                setCustomStyles
            )
        };
        
        configureTopImage();

        // Adiciona o event listener
        window.addEventListener('resize', configureTopImage);

        // Remove o event listener na limpeza do efeito
        return () => {
            window.removeEventListener('resize', configureTopImage);
        };
        
        getAllConfigurations()
            .then(data => {
                setConfigurations(data)
                setCustomStyles({ 
                    backgroundImage: `url(${process.env.REACT_APP_API_URL}${data['imagem-de-topo-pagina-pacotes-de-viagens-de-lazer']['value_translated']})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                })
            });



        
    },[]);

    return <section className='overflow-hidden'>
        <Row className='overflow-hidden'>
            <Col xs={12} className='h-[70vh]' style={customStyles}>
                <Row className='text-white py-[22vh] !leading-10'>
                    <Col 
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                    ></Col>
                    
                    <Col 
                        xs={10}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={4}
                    >
                        <div>
                            <h2 className="mb-[45px] ml-0 text-left tracking-wide text-shadow-large font-extralight text-white leading-[55px] font-serif text-shadow xl:!text-[30px] lg:!text-[30px] md:!text-[30px] md:!leading-[25px] sm:!text-[25px] sm:!mb-[30px] sm:!tracking-[-1px] sm:!leading-[43px] xs:!tracking-[-1px] xs:w-full xs:leading-none">
                                Pacotes de Viagens de Lazer
                            </h2>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} className='bg-[#F9F9FB]'>
                <h6 xs={12} className="my-10 font-serif text-[#0267DB] text-center font-[9px] uppercase leading-8 tracking-wide mb-[91px] xl:mb-[105px] lg:mb-[74px] md:mb-[65px] sm:mb-[53px] xs:mb-[46px] xxs:mb-[40px]">
                    Pacotes de Viagens de Lazer
                </h6>
                <Row className='mb-10'>
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        {(()=> {
                            if (pages && pages.length) {
                                return <InfoBannerStyle01
                                    grid="row row-cols-1 row-cols-lg-3 row-cols-sm-2 gap-y-10"
                                    className="justify-center"
                                    data={pages}
                                    animation={fadeIn}
                                />
                            }

                            return <CAlert>Não existe nenhum pacote de viagem de lazer disponível no momento.</CAlert>
                        })()}
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </Col>
        </Row>
    </section>
};

export default LeisureTravelPackages;