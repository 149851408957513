import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import axios from "axios";
import { CForm, CFormInput, CFormLabel, CFormTextarea, CSpinner } from "@coreui/react";
import Swal from "sweetalert2";
import { CFormInputWithMask } from "../../../../components/CFormInputWithMask";
import Recaptcha from "../../Components/Recaptcha";
import { getAllConfigurations, getTopImage } from "../../Functions/Utilities";

const Hotels = () => {
    const [loading, setLoading] = useState(false);

    const [pageText, setPageText] = useState("");

    const [configurations, setConfigurations] = useState(null);
    const [customStyles, setCustomStyles] = useState({
        backgroundColor: "#0068DB"
    });

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/frontend/configurations/one/texto-pagina-hospedagem`)
        .then((response) => {
            setPageText(response.data.value);
        })
        .catch((error) => {
            console.error(error);
        });
        
        const configureTopImage = () => {
            getTopImage(
                'imagem-de-topo-pagina-hospedagem',
                'imagem-mobile-de-topo-pagina-hospedagem', 
                setConfigurations, 
                setCustomStyles
            )
        };
        
        configureTopImage();

        // Adiciona o event listener
        window.addEventListener('resize', configureTopImage);

        // Remove o event listener na limpeza do efeito
        return () => {
            window.removeEventListener('resize', configureTopImage);
        };
    },[]);

    const [invalidInputs, setInvalidInputs] = useState({
        name: false,
        email: false,
        phone: false,
        message: false,
    });

    const [formData, setFormData] = useState({
        type: "hotels",
        name: '',
        email: '',
        phone: '',
        date_start: '',
        date_end: '',
        message: '',
        recaptcha: '',
    });

    const handleCaptchaChange = (value) => {
        setFormData({ ...formData, recaptcha: value });
    };
    
    const handleChange = (e) => {
        let { name, value } = e.target;
    
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        if (e) {
          e.preventDefault();
        }
    
        setLoading(true);

        let validationFailedFields = {};

        try {
            await axios.post(
                `${process.env.REACT_APP_API_URL}/frontend/service_contact`,
                formData
            );

            setLoading(false);

            Swal.fire({
                title: "Contato efetuado com sucesso!",
                icon: 'success',
                customClass: {
                    confirmButton: 'btn-fill !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] text-sm shadow-none !rounded-[50px]',
                    container: "tracking-wider",
                    popup: "tracking-wider",
                    header: "tracking-wider",
                    title: "tracking-wider",
                },
                buttonsStyling: false,
                confirmButtonText: 'OK',
            });

        } catch (error) {
            console.log(error?.response?.data);

            
            Swal.fire({
                title: error?.response?.data?.message,
                icon: 'error',
                customClass: {
                    confirmButton: 'btn-fill !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] text-sm shadow-none !rounded-[50px]',
                    container: "tracking-wider",
                    popup: "tracking-wider",
                    header: "tracking-wider",
                    title: "tracking-wider",
                },
                buttonsStyling: false,
                confirmButtonText: 'OK',
            });

            setLoading(false);
            if (error?.response?.data?.inputs) {
                validationFailedFields = {};
        
                Object.keys(invalidInputs).forEach((invalidInput) => {
                    validationFailedFields[invalidInput] = false;
                });
        
                Object.keys(error.response.data.inputs).forEach(
                    (invalidInput) => (validationFailedFields[invalidInput] = true)
                );
        
                setInvalidInputs(validationFailedFields);
            }
        }
    };

    return <section className='overflow-hidden'>
        <Row className='overflow-hidden'>
            <Col xs={12} className='h-[70vh]' style={customStyles}>
                <Row className='text-white py-[30vh]'>
                    <Col xs={2}></Col>
                    <Col xs={10} className='!text-4xl'>Hospedagem</Col>
                </Row>
            </Col>
            <Col xs={12} className='bg-[#FFFFFF]'>
                <Row className='py-[10vh]'>
                    <Col xl={3} xs={1}></Col>
                    <Col xl={6} xs={10}>
                        <CForm onSubmit={handleSubmit}>
                            <Row>
                                <Col xs={12} className='text-center mb-10' dangerouslySetInnerHTML={{__html: pageText || "Hospedagem" }} ></Col>
                                <Col xs={12} className='mt-3'>
                                    <CFormLabel className="text-black uppercase text-md" htmlFor="name">Nome</CFormLabel>
                                    <CFormInput 
                                        invalid={invalidInputs.name}
                                        value={formData.name}
                                        onChange={handleChange} 
                                        id="name"
                                        name="name">
                                    </CFormInput>
                                    
                                </Col>
                                <Col xs={12} className='mt-3'>
                                    <CFormLabel className="text-black uppercase text-md" htmlFor="email">Email</CFormLabel>
                                    <CFormInput 
                                        invalid={invalidInputs.email}
                                        value={formData.email}
                                        onChange={handleChange} 
                                        id="email"
                                        name="email">
                                    </CFormInput>
                                    
                                </Col>
                                <Col xs={12} className='mt-3'>
                                    <CFormLabel className="text-black uppercase text-md" htmlFor="phone">Telefone</CFormLabel>
                                    <CFormInputWithMask
                                        mask='(00) 0 0000-0000'
                                        invalid={invalidInputs.phone}
                                        value={formData.phone}
                                        onChange={handleChange}
                                        id='phone'
                                        name='phone'
                                    />
                                    
                                </Col>
                                <Col xs={6} className='mt-3'>
                                    <CFormLabel className="text-black uppercase text-md" htmlFor="start_date">
                                        Data de check-in
                                    </CFormLabel>
                                    <CFormInput 
                                        invalid={invalidInputs.start_date}
                                        value={formData.start_date}
                                        onChange={handleChange} 
                                        id="start_date"
                                        name="start_date"
                                        type="date">
                                    </CFormInput>
                                    
                                </Col>
                                <Col xs={6} className='mt-3'>
                                    <CFormLabel className="text-black uppercase text-md" htmlFor="end_date">Data de checkout</CFormLabel>
                                    <CFormInput 
                                        invalid={invalidInputs.end_date}
                                        value={formData.end_date}
                                        onChange={handleChange} 
                                        id="end_date"
                                        name="end_date"
                                        type="date">
                                    </CFormInput>
                                    
                                </Col>
                                <Col xs={12} className='mt-3'>
                                    <CFormLabel className="text-black uppercase text-md" htmlFor="message">Mensagem</CFormLabel>
                                    <CFormTextarea
                                        invalid={invalidInputs.message}
                                        value={formData.message}
                                        onChange={handleChange}
                                        id='message'
                                        name='message'
                                        rows={15}
                                    />
                                </Col>

                                <Col md={6} xs={12} className='mt-10'>
                                    <Recaptcha handleCaptchaChange={handleCaptchaChange}></Recaptcha>
                                </Col>
                                <Col md={6} xs={12} className="text-right mt-10">
                                    <Button disabled={loading} type="submit" className="btn-fill md:w-full xs:mt-8 sm:mt-8 !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[35px] !py-[17px] lg:!px-[32px] sm:!px-[22px] text-sm shadow-none !rounded-[50px] btn">
                                    Enviar &nbsp;
                                    { loading 
                                        ? (<CSpinner
                                            component='span'
                                            size='sm'
                                            aria-hidden='true'
                                        />) 
                                        : null
                                    }   
                                    </Button>
                                </Col>
                            </Row>
                        </CForm>
                    </Col>
                    <Col xl={3} xs={1}></Col>
                </Row>
            </Col>
        </Row>
    </section>
};

export default Hotels;