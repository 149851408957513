import React, { Suspense, useState } from "react";
import ScrollToTopButton from "./Components/ScrollToTop"
import FrontendHeader from "./Components/Header/FrontendHeader.js"
import { ParallaxProvider } from "react-scroll-parallax";
import { AnimatePresence, LazyMotion, domMax } from "framer-motion";

import "./Assets/css/icons.css"
import "./Assets/css/global.css"
import "./Assets/css/pages.css"
import "./fonts.css"
import "./index.scss"
import frontendRoutes from "../../frontendroutes.js";
import { Route, Routes } from "react-router-dom";
import FooterStyle06 from "./Components/Footers/FooterStyle06.jsx";
import { Col, Container, Row } from "react-bootstrap";
import Buttons from "./Components/Button/Buttons.jsx";
import { sendNewsletter } from "./Functions/Utilities.js";

const FrontendLayout = (props) => {
  const [newsletterEmail, setNewsletterEmail] = useState("");
  const [newsletterName, setNewsletterName] = useState("");
  const [newsletterCaptcha, setNewsletterCaptcha] = useState("");

  return (
    <LazyMotion features={domMax}>
      <ParallaxProvider>
        <ScrollToTopButton />
        <FrontendHeader props={props}></FrontendHeader>

        <AnimatePresence mode='wait'>
          <Suspense fallback={<></>}>
            <Routes>
              {frontendRoutes.map((frontendRoute, index) => {
                return (
                  <Route
                    key={index}
                    path={frontendRoute.path}
                    exact={frontendRoute.exact}
                    name={frontendRoute.name}
                    element={<frontendRoute.element />}
                  />
                );
              })}
            </Routes>
          </Suspense>
        </AnimatePresence>

        {/* Form de "Assine nossa newsletter" */}
        <section className="bg-[#0068DB] py-16">
          <Container>
            <Row>
              <Col md={3} xs={12} className="sm:mb-[20px] xd:!text-center">
                <p className="uppercase text-black tracking-wider my-4">Newsletter</p>
                <p className="text-white tracking-wider my-4 text-4xl">Receba nossa newsletter</p>
              </Col>
              <Col md={3} xs={12} className="flex justify-center items-center text-center sm:mb-[20px] pl-[1%] pr-[1%] py-[5%]">
                <input
                  className="bg-[#0068DB] border-white p-2 border-b w-full text-[#83A4E9] placeholder-[#83A4E9] mr-1" 
                  placeholder="Digite seu nome"
                  value={newsletterName}
                  onChange={(e) => {
                    setNewsletterName(e.target.value);
                  }}
                />
              </Col>
              <Col md={3} xs={12} className="flex justify-center items-center text-center sm:mb-[20px] pl-[1%] pr-[1%] py-[5%]">
                <input 
                  type="email" 
                  className="bg-[#0068DB] border-white p-2 border-b w-full text-[#83A4E9] placeholder-[#83A4E9]" 
                  placeholder="Digite seu email"
                  value={newsletterEmail}
                  onChange={(e) => {
                    setNewsletterEmail(e.target.value);
                  }}
                />
              </Col>
              <Col md={3} xs={12} className="flex justify-center items-center text-center sm:mb-[20px] tracking-[1rem]">
                <Buttons 
                  className="btn border-solid btn-fill xs:!w-full !mx-[0px] !border-[1px] !border-[#FFF] hover:!border-[#FFF] font-bold !tracking-normal font-serif uppercase lg:!mb-[15px] !bg-transparent hover:!bg-[#ac1221] !px-[82px] lg:!px-[42px] sm:!px-[42px] !py-[17px] text-sm shadow-none !rounded-[50px] text-white" 
                  title="Enviar"
                  onClick={() => sendNewsletter({
                    name: newsletterName,
                    email: newsletterEmail,
                    recaptcha: newsletterCaptcha,
                  })}
                ></Buttons>
              </Col>
            </Row>
          </Container>
        </section>
        {/* FIM Form de "Assine nossa newsletter" */}

        <FooterStyle06 theme="light" className="bg-white text-[#828282] seo-agency-footer_06" logo="/assets/img/webp/logo-gradient-magenta-orange.webp" />

      </ParallaxProvider>
    </LazyMotion>);
};


export default FrontendLayout;