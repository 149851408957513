import React from "react";
import { Menu } from "./Header";
import SocialIconsData from "./SocialIconsData";

const HeaderMenu = React.memo(function HeaderMenu(data) {
  const { headerData } = data;

  return (
    <>
      <div className="flex justify-between md:hidden col-lg-12 col-xl-12 mr-[0] !pl-[25px] !pr-[0px] lg:!pl-[5px] lg:!pr-0 ml-10">
        <Menu className="flex mr-5" data={headerData.slice(0, Math.floor(headerData.length / 2))} />
        <Menu className="flex ml-10" data={[...headerData.slice(Math.floor(headerData.length / 2), headerData.length), ...SocialIconsData]} />
      </div>
    </>
  );
}, () => false);

export default HeaderMenu;