import React from 'react';
import PropTypes from 'prop-types';

const AirlineOption = ({ option }) => {
  const departureDatetimes = [];
  const returnDatetimes = [];

  option.datetimes.forEach((dateTime) => {
    if (dateTime.type === 'return') {
      returnDatetimes.push(new Date(dateTime.date));
      return;
    }
    departureDatetimes.push(new Date(dateTime.date));
  });

  const groupDatesByDay = (dates) => {
    const groupedDates = {};

    dates.forEach((date) => {
      const day = date.toISOString().split('T')[0];
      if (!groupedDates[day]) {
        groupedDates[day] = [];
      }
      groupedDates[day].push(date);
    });

    const pairedDates = [];
    Object.values(groupedDates).forEach((dayDates) => {
      for (let i = 0; i < dayDates.length; i += 2) {
        pairedDates.push({
          start: dayDates[i],
          end: dayDates[i + 1] || null,
        });
      }
    });

    return pairedDates;
  };

  const groupedDepartureDatetimes = groupDatesByDay(departureDatetimes);
  const groupedReturnDatetimes = groupDatesByDay(returnDatetimes);

  return <>
    <div className='my-10 flex'>
      <div className='w-[50%] text-white py-12 px-10 text-right inline text-lg' style={{backgroundColor: "#56575B", borderTopLeftRadius: "12px", borderBottomLeftRadius: "12px", }}>
        Horários de voos <strong>{option.airline.name}</strong>
      </div>
      <div className='w-[50%] text-[#56575B] inline px-10 py-2 border border-[#56575B]' style={{backgroundColor: "#FFFFFF", borderTopRightRadius: "12px", borderBottomRightRadius: "12px", }}>
        <div>
          {groupedDepartureDatetimes.length > 0 &&
            groupedDepartureDatetimes.map((departureTime, index) => {
              const dateStart = new Date(departureTime.start);
              const dateEnd = new Date(
                departureTime.end || departureTime.start
              );
              return (
                <div key={index}>
                  <strong>IDA:</strong>{' '}
                  <strong>
                    {dateStart.toLocaleString('pt-br').split(', ').shift()}:
                  </strong>{' '}
                  {dateStart.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })}{' '}
                  &gt;{' '}
                  {dateEnd.toLocaleTimeString([], {
                    hour: '2-digit',
                    minute: '2-digit',
                    hour12: false,
                  })}
                  <br />
                </div>
              );
            })}
            {groupedReturnDatetimes.length > 0 && <br />}
            {groupedReturnDatetimes.length > 0 &&
              groupedReturnDatetimes.map((returnDatetime, index) => {
                const dateStart = new Date(returnDatetime.start);
                const dateEnd = new Date(
                  returnDatetime.end || returnDatetime.start
                );
                return (
                  <div key={index}>
                    <strong>VOLTA:</strong>{' '}
                    <strong>
                      {dateStart.toLocaleString('pt-br').split(', ').shift()}:
                    </strong>{' '}
                    {dateStart.toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })}{' '}
                    &gt;{' '}
                    {dateEnd.toLocaleTimeString([], {
                      hour: '2-digit',
                      minute: '2-digit',
                      hour12: false,
                    })}
                    <br />
                  </div>
                );
              })}
          </div>
      </div>
    </div>
  </>
};

AirlineOption.propTypes = {
  option: PropTypes.object.isRequired,
};

export default AirlineOption;
