import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { getAllConfigurations, getTopImage } from "../Functions/Utilities";

const About = () => {
    const [aboutInfo, setAboutInfo] = useState(null);
    
    const [configurations, setConfigurations] = useState(null);
    const [customStyles, setCustomStyles] = useState({
        backgroundColor: "#0068DB"
    });

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/frontend/about`)
            .then(response => {
                setAboutInfo(response?.data?.data);
            });

        const configureTopImage = () => {
            getTopImage(
                'imagem-de-topo-pagina-quem-somos',
                'imagem-mobile-de-topo-pagina-quem-somos',
                setConfigurations, 
                setCustomStyles
            )
        };
        
        configureTopImage();

        // Adiciona o event listener
        window.addEventListener('resize', configureTopImage);

        // Remove o event listener na limpeza do efeito
        return () => {
            window.removeEventListener('resize', configureTopImage);
        };
    },[]);

    return <section className='overflow-hidden'>
        <Row className='overflow-hidden'>
            <Col xs={12} className='h-[70vh]' style={customStyles}>
                <Row className='text-white py-[30vh]'>
                    <Col xs={2}></Col>
                    <Col xs={8} className='!text-4xl lg:px-16'>Quem Somos</Col>
                </Row>
            </Col>
            <Col xs={12} className='bg-[#FFFFFF]'>
                <Row className='py-[10vh]'>
                    <Col xs={2}></Col>
                    <Col lg={4} className='text-[32px] text-black lg:px-16'>
                        <h2 className='block pr-6 lg:pr-0'>Mais de 40 anos de história atendendo com excelência</h2>
                        <span className='flex items-center text-[14px] uppercase after:block after:bg-black after:h-[1px] after:w-24 after:ml-3 lg:my-10'>Quem somos</span>
                    </Col>
                    <Col lg={4} className='leading-8 text-black lg:px-16' dangerouslySetInnerHTML={{__html: aboutInfo?.value}}></Col>
                    <Col xs={2}></Col>
                </Row>
            </Col>
        </Row>
    </section>
};

export default About;