import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { Autoplay, Pagination, Keyboard, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Buttons from '../Components/Button/Buttons';
import axios from 'axios';
import InteractiveBanners15 from '../Components/InteractiveBanners/InteractiveBanners15';
import { fadeIn } from '../Functions/GlobalAnimations';
import InfoBannerStyle01 from '../Components/InfoBanner/InfoBannerStyle01';
import Debug from '../Components/Debug/Debug';

const Home = (props) => {
  const [homeData, setHomeData] = useState({});
  const [swiperSlideData, setSwiperSlideData] = useState([]);

  useEffect(() => {
    try {

      // Infelizmente há um problema de cache de css nessa tela, bugando o menu de topo. 
      // Devido à falta de tempo e pressa para entregar esse projeto, infelizmente tive que fazer esse "paliativo permantente" :(
      setInterval(() => {
        const elements = document.querySelectorAll('.mobile-logo');
        elements.forEach(ele => {
            const styles = window.getComputedStyle(ele);
            if (styles.lineHeight !== '30px') {
                window.location.reload();
            }
        });
      }, 200);

      axios.get(`${process.env.REACT_APP_API_URL}/frontend/home`)
        .then(response => {
          setHomeData(response.data);

          if (
            response.data?.banners_data
            && (response.data?.banners_data['Página inicial - Topo'] || false)
          ) {
            let carrosselData = response.data.banners_data['Página inicial - Topo'].banners;

            const configureCarrossel = () => {
                // Adicione aqui a função que você deseja executar ao redimensionar a tela
                setSwiperSlideData(carrosselData.map((bannerData, index) => {
                  return {
                    id: index,
                    title: bannerData.title,
                    conditions: bannerData.content,
                    img: window.innerWidth < 768 ? bannerData.mobileImg : bannerData.img,
                    isBase64: false, 
                    url: `#${bannerData.btnLink}`,
                  };
                }));
            };
            
            configureCarrossel();

            // Adiciona o event listener
            window.addEventListener('resize', configureCarrossel);
    
            // Remove o event listener na limpeza do efeito
            return () => {
                window.removeEventListener('resize', configureCarrossel);
            };

          }

          setSwiperSlideData(response.data.future_events.map(futureEvent => {
            return {
              id: futureEvent.id,
              title: futureEvent.name,
              conditions: futureEvent.conditions,
              organizer: futureEvent.organizer,
              img: futureEvent.banners[0].path,
              isBase64: futureEvent.banners[0].isBase64,
              url: `#/frontend/event/${futureEvent.id}`,
            };
          }));

        });
    } catch (error) {
      console.error('Erro ao obter configurações:', error);
    }
  }, []);

  return <>
    <div className="bg-white overflow-x-hidden">
      {/* Banner do topo da página, slider com eventos ativos e futuros */}
      <section className="overflow-hidden full-screen h-[100vh]">
        <Swiper
          className="white-move swiper-pagination-light swiper-pagination-medium h-full relative swiper-navigation-04 swiper-navigation-dark travel-agency-slider"
          slidesPerView={1}
          loop={true}
          keyboard={true}
          navigation={true}
          modules={[Pagination, Autoplay, Keyboard, Navigation]}
          pagination={{ clickable: true }}
          autoplay={{ delay: 4500, disableOnInteraction: false }}
          breakpoints={{
            575: {
              pagination: true,
            }
          }}
        >
        {
          swiperSlideData && swiperSlideData.map((item, i) => {
            return (
              <SwiperSlide key={i} style={{ backgroundImage: `url(${item.img})`, paddingTop: "200px", paddingBottom: "200px"}} className="bg-no-repeat	bg-cover	overflow-hidden bg-center">
                <Container className="h-full text-left justify-left xs:p-0">
                  <Row className="h-full w-[92%] my-0 mx-auto">
                    <Col className="h-full justify-left flex-col relative flex xs:p-0">
                      <span className="mb-[35px] font-medium tracking-[2px] text-white text-[11px] font-serif uppercase block sm:text-[11x] sm:mb-[15px] xs:w-full"> {item.title} </span>
                      <h2 
                        className="mb-[45px] lg:max-w-none max-w-[60%] ml-0 text-left tracking-wide text-shadow-large font-extralight text-white leading-[70px] font-serif text-shadow lx:!text-[40px] lg:!text-[38px] md:!text-[30px] md:!leading-[43px] sm:!text-[25px] sm:!mb-[30px] sm:!tracking-[-1px] sm:!leading-[43px] xs:!tracking-[-1px] xs:w-full xs:leading-2" 
                        dangerouslySetInnerHTML={{__html: item.conditions}} 
                      />
                      <div className="inline-block">
                        <Buttons color="#fff" ariaLabel="button link for swiper img" href={item.url} className="btn-fill !mx-[0px] !border-[2px] !border-[#DC3545] hover:!border-[#ac1221] font-bold !tracking-wider font-serif uppercase lg:!mb-[15px] text-white !bg-[#DC3545] hover:!bg-[#ac1221] danger !px-[82px] !py-[17px] text-sm shadow-none !rounded-[50px]" size="lg" title="Saiba mais" />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </SwiperSlide>
            )
          })
        }
        </Swiper>
      </section>
      {/* Fim do banner de topo */}

      {/* Section Start */}
      <section className="bg-[#FFF] py-[50px]">
        <h6 className="font-serif text-[#0267DB] mb-3 text-center text-[12px] uppercase leading-8 tracking-wide">
          NOSSOS DESTAQUES
        </h6>
        <div className="block px-4 mb-[50px] w-full"> 
          <span className="text-[24px] block text-center mb-3 text-black">Tenha acesso rápido aos Serviços da Lemmertz Turimo</span>
          <span className="text-[16px] block text-center text-black leading-5">
            Mais de 40 anos de experiência em proporcionar a você as melhores opções do mercado.<br/> 
            Confira abaixo algumas das escolhas que selecionamos para você. 
          </span>
        </div>
        <Container fluid className="xs:px-0">
          {
            homeData['banners_data'] &&
            homeData['banners_data']["Destaques"] &&
            <InteractiveBanners15 
              title={homeData['banners_data']['Destaques']['title']} 
              data={homeData['banners_data']["Destaques"]["banners"]} 
              grid="row-cols-1 row-cols-xl-4 row-cols-md-2 gap-y-0 mx-[0%] md:mx-0" 
              animation={fadeIn} 
              animationDelay={0.3} 
            />
          }
        </Container>
      </section>
      {/* Section End */}

      {/* Section Start */}
      <section className="bg-[#FFF] pb-[50px]">
        <h6 className="font-serif text-[#0267DB] text-center text-[12px] uppercase leading-8 tracking-wide mb-3">
          NOSSAS NOVIDADES
        </h6>
        <div className="block px-4 mb-[50px] w-full"> 
          <span className="text-[24px] block text-center mb-3 text-black">Acontece na Lemmertz Turismo</span>
          <span className="text-[16px] block text-center text-black leading-5">Confira algumas notícias que selecionamos para você.</span>
        </div>
        <Container fluid className="xs:px-0">
          {
            homeData['news'] &&
            homeData['news'].length &&
            <Col xs={12} className='bg-[#FFF]'>
              <Row className='mb-10'>
                <Col xs={1}></Col>
                <Col xs={10}>
                    { homeData['news'] && <InfoBannerStyle01
                      grid="row row-cols-1 row-cols-lg-3 row-cols-sm-2 gap-y-10"
                      className="justify-center"
                      data={homeData['news']}
                    animation={fadeIn}
                  />}
                </Col>
                <Col xs={1}></Col>
              </Row>
            </Col>
          }
        </Container>
      </section>

    </div>
  </>
}

export default Home;