import React, { memo, useEffect, useState } from 'react'

// Libraries
import { Col, Container, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { PropTypes } from "prop-types";

// Components
import FooterMenu, { Footer } from './Footer';

//Data
import FooterData from './FooterData';
import { getAllConfigurations } from '../../Functions/Utilities';

const FooterStyle06 = (props) => {
    const [footerData, setFooterData] = useState([]);
    const [configurations, setConfigurations] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
          try {
            const data = await getAllConfigurations();
      
            let footerDataToShow = FooterData;

            if (data && data['endereco'] && data['endereco']['value_translated']) {
                if (!footerDataToShow.some(item => 'Endereço' === item.title)) {
                    const newTelefonesItem = {
                        title: 'Endereço',
                        submenu: [
                            { _html: `<strong>Email:</strong> ${data['email-do-footer']['value']}`, link: `` },
                            { _html: data['endereco']['value'], link: `` },
                        ]
                    };

                    footerDataToShow.splice(footerDataToShow.length - 1, 0, newTelefonesItem);
                }
            }

            if (data && data['telefones'] && data['telefones']['value_translated']) {
              if (!footerDataToShow.some(item => 'Telefones' === item.title)) {
                const newTelefonesItem = {
                  title: 'Telefones',
                  submenu: Object.entries(data['telefones']['value_translated']).map(([key, value]) => {
                    return { title: `${key}: ${value}`, link: `tel: ${value}`, _html: `<strong>${key}</strong>: ${value}` };
                  }),
                };
      
                footerDataToShow.splice(footerDataToShow.length - 1, 0, newTelefonesItem);
              }
            }
            setFooterData(footerDataToShow);
      
            setConfigurations(data);
          } catch (error) {
            console.error('Erro ao obter configurações:', error);
          }
        };
      
        fetchData();
      }, []);
      

    return (
        <Footer theme={props.theme} className={`${props.className}${props.theme ? ` ${props.theme}` : ""}`}>
            <div className="py-[5%] lg:py-[8%] md:py-[50px] sm:py-[50px] text-center">
                <Link aria-label="link" to="/" className="flex justify-center sm:justify-center">
                    {configurations && <img src={process.env.REACT_APP_API_URL + configurations['logo-principal-escuro']['value_translated']} alt="logo" width="111" height="36" />}
                </Link>
            </div>
            <div className="py-[5%] lg:py-[8%] md:py-[50px] sm:py-[50px]">
                <Container>
                    <Row md={4} className="justify-center gap-y-10">
                        <FooterMenu data={footerData.slice(0, 4)} md={3} sm={6} className="xl:px-[15px] md:mb-0 xs:mb-[25px] last:mb-0" titleClass="uppercase" />
                    </Row>
                </Container>
            </div>
            <div className={`py-[40px] border-t  ${props.theme === "light" ?  "border-mediumgray" : "border-[#ffffff1a]"}`}>
                <Container>
                    <Row>
                        <Col md={3} className="sm:mb-[20px]"></Col>
                        <Col md={6} className="flex justify-center items-center text-center sm:mb-[20px]">
                            <p className="mb-0">&copy; {new Date().getFullYear()} Plano</p>
                        </Col>
                        <Col md={3} className="xs:text-center">
                        </Col>
                    </Row>
                </Container>
            </div>
        </Footer>
    )
}

FooterStyle06.defaultProps = {
    data: FooterData,
    logo: "/assets/img/webp/logo-white.webp"
}

FooterStyle06.propTypes = {
    className: PropTypes.string,
    logo: PropTypes.string,
}

export default memo(FooterStyle06)