import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { fadeIn } from "../Functions/GlobalAnimations";
import InfoBannerStyle01 from "../Components/InfoBanner/InfoBannerStyle01";
import { getAllConfigurations, getTopImage } from "../Functions/Utilities";

const Blog = () => {
    const [pages, setPages] = useState(null);
    const [configurations, setConfigurations] = useState(null);
    const [customStyles, setCustomStyles] = useState({
        backgroundColor: "#0068DB",
    });

    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/frontend/blog`)
            .then(response => {
                setPages(response?.data?.data);
            });
        
        const configureTopImage = () => {
            getTopImage(
                'imagem-de-topo-pagina-blog',
                'imagem-mobile-de-topo-pagina-blog',
                setConfigurations, 
                setCustomStyles
            )
        };
        
        configureTopImage();

        // Adiciona o event listener
        window.addEventListener('resize', configureTopImage);

        // Remove o event listener na limpeza do efeito
        return () => {
            window.removeEventListener('resize', configureTopImage);
        };
    },[]);

    return <section className='overflow-hidden'>
        <Row className='overflow-hidden'>
            <Col xs={12} className='h-[70vh]' style={customStyles}>
                <Row className='text-white py-[22vh] !leading-10'>
                    <Col 
                        xs={2}
                        sm={2}
                        md={2}
                        lg={2}
                        xl={2}
                    ></Col>
                    
                    <Col 
                        xs={10}
                        sm={6}
                        md={4}
                        lg={4}
                        xl={3}
                    >
                        <div>
                            <span className="mb-[35px] font-medium tracking-[2px] text-white text-[11px] font-serif uppercase block sm:text-[11x] sm:mb-[15px] xs:w-full"> 
                                Blog
                            </span>
                        </div>
                            <div>
                            <h2 className="mb-[45px] ml-0 text-left tracking-wide text-shadow-large font-extralight text-white leading-[55px] font-serif text-shadow xl:!text-[30px] lg:!text-[30px] md:!text-[30px] md:!leading-[25px] sm:!text-[25px] sm:!mb-[30px] sm:!tracking-[-1px] sm:!leading-[43px] xs:!tracking-[-1px] xs:w-full xs:leading-none">
                                Fique por dentro das novidades da Lemmertz Turismo
                            </h2>
                        </div>
                    </Col>
                </Row>
            </Col>
            <Col xs={12} className='bg-[#F9F9FB]'>
                <h6 xs={12} className="my-10 font-serif text-[#0267DB] text-center font-[9px] uppercase leading-8 tracking-wide mb-[91px] xl:mb-[105px] lg:mb-[74px] md:mb-[65px] sm:mb-[53px] xs:mb-[46px] xxs:mb-[40px]">
                    Blog
                </h6>
                <Row className='mb-10'>
                    <Col xs={1}></Col>
                    <Col xs={10}>
                        {pages && <InfoBannerStyle01
                            grid="row row-cols-1 row-cols-xl-3 row-cols-lg-2 row-cols-sm-1 gap-y-10"
                            className="justify-center"
                            data={pages}
                            animation={fadeIn}
                        />}
                    </Col>
                    <Col xs={1}></Col>
                </Row>
            </Col>
        </Row>
    </section>
};

export default Blog;