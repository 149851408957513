import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import axios from "axios";
import { CFormCheck, CFormLabel } from "@coreui/react";
import InfoBannerStyle01 from "../Components/InfoBanner/InfoBannerStyle01";
import { getAllConfigurations, getTopImage } from "../Functions/Utilities";

const PackagesDestinations = () => {
    const [filters, setFilters] = useState([]);
    const [formData, setFormData] = useState({
        origin_cities: [],
        destiny_cities: [],
        events: [],
    });

    const [packages, setPackages] = useState([]);

    const [pageText, setPageText] = useState("");

    const [configurations, setConfigurations] = useState(null);
    const [customStyles, setCustomStyles] = useState({
        backgroundColor: "#0068DB"
    });

    useEffect(() => {

        axios
            .get(`${process.env.REACT_APP_API_URL}/frontend/packages/filters`)
            .then(response => {
                setFilters(response?.data?.data);
            });

        searchPackages()

        axios
            .get(`${process.env.REACT_APP_API_URL}/frontend/configurations/one/texto-pagina-destinos-turisticos-e-eventos`)
            .then((response) => {
                setPageText(response.data.value);
            })
            .catch((error) => {
                console.error(error);
            });

        const configureTopImage = () => {
            getTopImage(
                'imagem-de-topo-pagina-pacotes-e-destinos',
                'imagem-mobile-de-topo-pagina-pacotes-e-destinos',
                setConfigurations, 
                setCustomStyles
            )
        };
        
        configureTopImage();

        // Adiciona o event listener
        window.addEventListener('resize', configureTopImage);

        // Remove o event listener na limpeza do efeito
        return () => {
            window.removeEventListener('resize', configureTopImage);
        };
    },[]);

    useEffect(() => {
        searchPackages();
    }, [formData]);

    const searchPackages = () => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/frontend/packages/search-destination-page`, { params: formData })
            .then(response => {
                setPackages(response?.data?.data);
            });
    };

    return <section className='overflow-hidden'>
        <Row className='overflow-hidden'>
            <Col xs={12} className='h-[70vh]' style={customStyles}>
                <Row className='text-white py-[30vh]'>
                    <Col xs={2}></Col>
                    <Col xs={10} className='!text-4xl'>Pacotes e destinos</Col>
                </Row>
            </Col>
            <Col xs={12} className='bg-[#FFFFFF]'>
                <Row className='py-[10vh]'>

                    <Col xs={12} className='bg-[#FFFFFF]'>
                        <Row className=''>
                            <Col xl={4} xs={1}></Col>
                            <Col xl={4} xs={10}>
                                <Row>
                                    <Col xs={12} className='text-center mb-10' dangerouslySetInnerHTML={{__html: pageText || "Pacotes e destinos" }} ></Col>
                                </Row>
                            </Col>
                            <Col xl={4} xs={1}></Col>
                        </Row>
                    </Col>

                    <Col xs={2}></Col>
                    {/* <Col xs={2}>
                        <Row>
                            {filters && Object.entries(filters).map(([key, value], idx) => {
                                const {filter_title, data} = value;

                                return <Col xs={12} key={key + idx} className="mb-10">
                                    <p className="mb-4"><strong className="text-xlg">{filter_title}</strong></p>
                                
                                    {data && data.map((filter_data, fdindex) => {

                                        return <div key={fdindex} className="flex">
                                            <CFormCheck
                                                value={filter_data.value}
                                                id={`${key}-${filter_data.value}`}
                                                checked={formData[key].includes(filter_data.value)}
                                                onChange={e => {
                                                    const checkbox_value = e.target.value;
                                                    let type_values = formData[key];

                                                    if (e.target.checked) {
                                                        type_values.push(checkbox_value);
                                                        type_values = [...new Set(type_values)];
                                                    } else {
                                                        type_values = type_values.filter(item => item !== checkbox_value);
                                                    }

                                                    setFormData({...formData, [key]: type_values});
                                                }}
                                            /> &nbsp;
                                            <CFormLabel className="text-black text-md" htmlFor={`${key}-${filter_data.value}`}>{filter_data.title}</CFormLabel>
                                        </div>
                                    })}

                                </Col>

                            })}
                        </Row>
                        
                    </Col> */}
                    <Col xs={8} className='leading-8'>
                        {packages && <InfoBannerStyle01
                            grid="row row-cols-1 row-cols-lg-3 row-cols-sm-1 gap-y-10"
                            className="justify-center"
                            data={packages}
                        />}
                    </Col>
                    <Col xs={2}></Col>
                </Row>
            </Col>
        </Row>
    </section>
};

export default PackagesDestinations;