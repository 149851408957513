const SocialIconsData = [
    {
      link: "https://www.instagram.com/lemmertzturismo/",
      icon: "fab fa-instagram"
    },
    {
      link: "https://wa.me/5561999951777",
      icon: "fab fa-whatsapp"
    },
];

export default SocialIconsData;